'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _util = require('./util');

var DEFAULT_SETTINGS = {
	sentences: ['Hello typing.js'],
	caretChar: '_',
	caretClass: 'typingjs__caret',

	ignoreContent: false,
	ignorePrefix: false,
	typeDelay: 50,
	sentenceDelay: 750,
	humanize: true,

	onType: undefined,
	onBackspace: undefined,
	onFinish: undefined,
	onSentenceFinish: undefined
};

var Typing = {
	new: function _new(selector, options) {
		var elements = document.querySelectorAll(selector);
		this.withElements(elements, options);
	},

	withElements: function withElements(elements, options) {
		// Settings.
		var settings = (0, _util.merge)(DEFAULT_SETTINGS, options);

		Array.prototype.map.call(elements, function (el) {
			// Creates initial elements.
			var initialText = settings.ignoreContent ? '' : el.textContent;

			var content = document.createElement('span');
			content.className = 'typingjs__content';
			content.textContent = initialText;

			var caret = document.createElement('caret');
			caret.className = settings.caretClass;
			caret.textContent = settings.caretChar;

			el.innerHTML = '';
			el.appendChild(content);
			el.appendChild(caret);

			// Starts progress here.
			var sentencesLeft = settings.sentences.slice();

			function typeSentence(typer) {
				// Reads next iteration of the typing animation.
				var _typer = typer(),
				    current = _typer.current,
				    isType = _typer.isType,
				    isBackspace = _typer.isBackspace,
				    isDone = _typer.isDone;

				content.textContent = current;

				if (isDone) {
					if ((0, _util.isFunction)(settings.onSentenceFinish)) settings.onSentenceFinish.call(this_);
					typeArray();
				} else {
					// Callbacks.
					if (isType && (0, _util.isFunction)(settings.onType)) settings.onType.call(this_);
					if (isBackspace && (0, _util.isFunction)(settings.onBackspace)) settings.onBackspace.call(this_);

					// Next step
					var humanTimeout = settings.typeDelay;
					if (settings.humanize) humanTimeout = (0, _util.noise)(settings.typeDelay, settings.typeDelay);
					setTimeout(typeSentence, humanTimeout, typer);
				}
			}

			function typeArray() {
				var targetStr = (0, _util.head)(sentencesLeft);
				sentencesLeft = (0, _util.tail)(sentencesLeft);
				if (targetStr !== undefined) {
					var typer = (0, _util.makePrefixTyper)(content.textContent, targetStr);
					if (settings.ignorePrefix) {
						typer = (0, _util.makeTyper)(content.textContent, targetStr, function (curr) {
							return curr.length == 0;
						});
					}
					setTimeout(typeSentence, settings.sentenceDelay, typer);
				} else if ((0, _util.isFunction)(settings.onFinish)) {
					settings.onFinish.call(this_);
				}
			}

			typeArray();
		});
	}
};

if (typeof jQuery != 'undefined') {
	(function ($) {
		$.fn.typing = function (options) {
			Typing.withElements(this.get(), options);
		};
	})(jQuery);
}

window.Typing = Typing;
exports.default = Typing;
